import { CirclePlus } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import CardWrapper from '@/core/components/ui/CardWrapper.vue';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import CertificationStatus from '@/modules/account-details/components/certificate/CertificateStatusComponent.vue';
import CertificationTemplateRenderer from '@/modules/account-details/components/certificate/CertificateTemplateRenderer.vue';
import CertificateTypeSelector from '@/modules/account-details/components/certificate/CertificateTypeSelectorComponent.vue';
import CertificateVerificationState from '@/modules/account-details/components/certificate/CertificateVerificationStateComponent.vue';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { USER_CERTIFICATES_CACHE } from '@/store/modules/attachment-cache/constants';
import { CERTIFICATES_STORE } from '../../../store/modules/certificates';
import { USERS_STORE } from '../../../store/modules/users';
export default defineComponent({
    name: 'certification-page',
    components: {
        CardWrapper,
        CertificateTypeSelector,
        CertificateVerificationState,
        CertificationTemplateRenderer,
        CertificationStatus,
        CirclePlus
    },
    data() {
        return {
            activeCertificate: null,
            isCreateNewCertificate: false,
            unsaveUserCerficates: [],
            refNewUserCertificateForm: 0,
            UserVerificationStatusEnum,
            submitting: false
        };
    },
    created() {
        this.getCertificates();
        this.getUserCertificates(this.user.id);
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user', 'userCertificates', 'certificateVerificationState']),
        ...mapGetters(CERTIFICATES_STORE, ['certificates']),
        isHiddenCertificateTypeSelector() {
            return this.unsaveUserCerficates.length > 0 || this.userCertificates.length > 0;
        },
        disabled() {
            return this.user.userVerificationStatus === UserVerificationStatusEnum.PENDING_VERIFICATION;
        }
    },
    methods: {
        ...mapActions(CERTIFICATES_STORE, ['getCertificates']),
        ...mapActions(USERS_STORE, [
            'addNewCertificate',
            'getUserCertificates',
            'updateUserCertificate',
            'removeUserCertificates',
            'getAccountStatuses'
        ]),
        ...mapActions(ATTACHMENT_CACHE_STORE, ['resetCacheEntity']),
        onSelectCertificateType(certificate) {
            if (this.isCreateNewCertificate || !this.unsaveUserCerficates.length) {
                const newCertificate = {
                    certificateId: certificate.id,
                    template: certificate.template,
                    name: certificate.name,
                    userId: this.user.id,
                    metadata: {},
                };
                this.unsaveUserCerficates = [...this.unsaveUserCerficates, newCertificate];
                this.isCreateNewCertificate = false;
            }
        },
        onSubmitNewCertificate(certificate) {
            const userCertificate = {
                userId: this.user.id,
                certificateId: certificate.certificateId,
                metadata: certificate.metadata,
                attachment: certificate.attachment,
            };
            this.submitting = true;
            this.addNewCertificate(userCertificate)
                .then(async () => {
                this.unsaveUserCerficates = [];
                this.$notify.success({
                    message: 'New certificate successfully saved.'
                });
                await this.getAccountStatuses();
                await this.getCertificates();
            })
                .catch((e) => {
                this.$notify.error({
                    message: e || 'Error submitting certificate details.'
                });
            })
                .finally(() => {
                this.submitting = false;
            });
        },
        onAddCertification() {
            if (!this.isCreateNewCertificate && this.unsaveUserCerficates.length === 0) {
                this.isCreateNewCertificate = true;
            }
        },
        onRemoveCertificate(userCertificateId) {
            this.removeUserCertificates({
                userId: this.user.id,
                userCertificateId
            })
                .then(async () => {
                this.$notify.success({
                    message: 'Certificate successfully removed.'
                });
                await this.getAccountStatuses();
                await this.getCertificates();
            })
                .catch((e) => {
                this.$notify.error({
                    message: e || 'Error removing certificate details.'
                });
            })
                .finally(() => {
                this.resetCacheEntity(USER_CERTIFICATES_CACHE);
            });
        },
        onRemoveUnSaveCertificate() {
            this.unsaveUserCerficates = [];
        },
        nextSection() {
            this.$router.push({ name: 'account/insurance' });
        },
        onSubmit(payload) {
            const { attachment, certificate } = payload;
            const proof = Array.isArray(attachment) ? attachment[0] : attachment;
            this.submitting = true;
            this.updateUserCertificate({
                id: payload.id,
                userId: this.user.id,
                certificateId: certificate.id,
                certificate: { metadata: payload.metadata, certificateId: certificate.id, attachment: proof }
            })
                .then(async () => {
                this.unsaveUserCerficates = [];
                this.$notify.success({
                    message: 'Certificate successfully updated'
                });
                await this.getAccountStatuses();
                await this.getCertificates();
            })
                .catch((e) => {
                this.$notify.error({
                    message: e || 'Error submitting certificate details.'
                });
            })
                .finally(() => {
                this.submitting = false;
                this.resetCacheEntity(USER_CERTIFICATES_CACHE);
            });
        },
    }
});
