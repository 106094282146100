import { defineComponent } from 'vue';
export default defineComponent({
    name: 'certificate-type-selector-component',
    props: {
        certificates: {
            type: Array,
            required: true
        },
        label: String,
    },
    computed: {
        caption() {
            return this.label || 'Certification Type';
        }
    },
    data() {
        return {
            selectedCertificate: null,
        };
    },
    methods: {
        onChangeCertificate() {
            // filter the selected certificate object
            const certificate = this.certificates.find((cert) => cert.id === this.selectedCertificate);
            this.$emit('on-select', certificate);
        }
    }
});
