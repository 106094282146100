import { Document } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import ImageLoading from '@/core/components/ui/ImageLoading.vue';
import { AUTH_TOKEN } from '@/core/constants';
import { ImageFileTypes } from '@/core/helpers/file.helper';
import { getLocalStorageWithExpiry } from '@/core/utils/common';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { USER_CERTIFICATES_CACHE, USER_INSURANCES_CACHE } from '@/store/modules/attachment-cache/constants';
export default defineComponent({
    name: 'file-upload-component',
    components: {
        ImageLoading,
        Document
    },
    props: {
        record: {
            type: Object,
            required: true,
        },
        submitting: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
            attachment: null,
            currentAttachment: null,
            embedSrc: '',
            isUploading: false,
            isFileAnImage: false
        };
    },
    created() {
        const { attachment } = this.currentRecord;
        if (attachment && attachment.length) {
            this.loadAttachment(attachment[0]);
        }
    },
    computed: {
        currentRecord() {
            return this.record;
        },
        uploadHeaders() {
            const token = getLocalStorageWithExpiry(AUTH_TOKEN);
            return {
                Authorization: `Bearer ${token}`
            };
        }
    },
    methods: {
        ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments']),
        onProgressUploadProfileImage() {
            this.isUploading = true;
        },
        handleErrorFiles(err) {
            this.$notify.error({
                title: 'Upload Error',
                message: err || 'Uploading files error.'
            });
            this.isUploading = false;
        },
        updateProfileImageOnSuccess(response) {
            this.attachment = null;
            this.updateProofValue(response);
            this.attachment = response.src;
            this.isUploading = false;
            this.isFileAnImage = this.fileIsImage(response.originalName);
        },
        updateProofValue(value) {
            this.$emit('updateProof', value);
        },
        loadAttachment(value) {
            const { attachment } = value;
            const cache = this.getCache(attachment);
            this.currentAttachment = attachment;
            this.getAttachments({
                name: cache,
                attachments: [value],
                includeUrl: true,
                returnFirstOnly: true
            })
                .then(({ src, url }) => {
                this.attachment = src;
                if (this.isPdfFile(url)) {
                    this.embedSrc = url;
                    this.attachment = '';
                }
                this.isFileAnImage = this.fileIsImage(value.originalName);
            }).catch(() => { });
        },
        getCache(value) {
            let cache;
            if (value.indexOf('insurances') !== -1) {
                cache = USER_INSURANCES_CACHE;
            }
            if (value.indexOf('certifications') !== -1) {
                cache = USER_CERTIFICATES_CACHE;
            }
            return cache;
        },
        isPdfFile(type) {
            let bool = type === 'application/pdf';
            if (type && type.indexOf('.pdf') > -1) {
                bool = true;
            }
            return bool;
        },
        fileIsImage(filename) {
            const imageTypes = ImageFileTypes;
            if (!filename)
                return false;
            const extension = filename.split('.')[filename.split('.').length - 1];
            return imageTypes.includes(extension.toLowerCase());
        },
    }
});
