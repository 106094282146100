import { defineComponent } from 'vue';
export default defineComponent({
    name: 'NotRequired',
    props: ['certificate', 'disabled'],
    computed: {
        currentCertificate() {
            return this.certificate;
        }
    },
    data() {
        return {
            rules: {
                commentbox: [
                    {
                        required: true,
                        message: 'A comment is required',
                        trigger: 'blur'
                    }
                ],
            }
        };
    },
    methods: {
        onSubmit() {
            this.$refs.notRequired
                .validate((valid) => {
                if (valid) {
                    this.$emit('on-submit', this.certificate);
                }
            });
        }
    },
});
