import { AttachmentFileTypesEnum, AttachmentImagesTypesEnum } from '@/core/packages/shared-library';
const ImageFileTypes = [
    AttachmentImagesTypesEnum.BMP,
    AttachmentImagesTypesEnum.GIF,
    AttachmentImagesTypesEnum.JPEG,
    AttachmentImagesTypesEnum.JPG,
    AttachmentImagesTypesEnum.PNG,
    AttachmentImagesTypesEnum.TIFF,
    // needs to be transferred to iknowa shared
    'rle',
    'dib',
    'jpe',
    'exif',
    'tiff',
    'tif',
    'icon',
    'ico',
    'mwf',
    'emf'
];
const DocumentFileTypes = [
    AttachmentFileTypesEnum.PDF,
    AttachmentFileTypesEnum.DOC,
    AttachmentFileTypesEnum.DOCX,
    // needs to be transferred to iknowa shared
    'pptx',
    'text',
    'txt',
    'mov',
    'mp4',
    'mpg',
    'mpeg',
    'heic'
];
const getFileType = (filename) => {
    let fileType = '';
    if (filename && typeof filename === 'string') {
        fileType = filename.split('.')[filename.split('.').length - 1];
    }
    return fileType;
};
const isPdfFile = (type) => {
    let bool = type === 'application/pdf';
    if (type && type.indexOf('.pdf') > -1) {
        bool = true;
    }
    return bool;
};
const mbToGbSize = 0.0009765625; // 1GB
const bytesToGBSize = 1073741824; // GB
const isImageAttachment = (filename) => {
    const extension = getFileType(filename);
    return ImageFileTypes.includes(extension.toLowerCase());
};
const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes)
        return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    // eslint-disable-next-line no-restricted-properties
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
const bytesToMb = (bytes) => {
    return bytes ? parseFloat(formatBytes(bytesToGBSize - bytes)) : 0;
};
const formatBytesToGB = (bytes, capacity) => {
    const getInMb = bytesToMb(bytes);
    const finalTotalInGb = Number((getInMb * capacity) * mbToGbSize).toPrecision(10);
    return finalTotalInGb.slice(0, 4);
};
const getMBPercentage = (bytes, capacity) => {
    return 100 - Math.floor((100 * (bytesToMb(bytes) * capacity)) / (1024 * capacity));
};
export { getFileType, ImageFileTypes, DocumentFileTypes, isImageAttachment, isPdfFile, formatBytes, formatBytesToGB, getMBPercentage, bytesToMb };
