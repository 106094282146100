import { defineComponent } from 'vue';
import FileUploadComponent from '@/modules/account-details/components/FileUploadComponent.vue';
export default defineComponent({
    name: 'NFRC',
    props: ['certificate', 'disabled'],
    components: {
        FileUploadComponent
    },
    computed: {
        currentCertificate() {
            return this.certificate;
        }
    },
    data() {
        return {
            rules: {
                postcode: [
                    {
                        required: true,
                        message: 'Postcode is required',
                        trigger: 'blur'
                    }
                ],
                businessName: [
                    {
                        required: true,
                        message: 'Business name is required',
                        trigger: 'blur'
                    }
                ],
                url: [
                    {
                        required: true,
                        message: 'URL is required',
                        trigger: 'blur'
                    }
                ],
            },
            proofRequired: true,
            attachment: ''
        };
    },
    created() {
        const { attachment } = this.$props.certificate;
        const proof = attachment ? attachment[0] : '';
        if (proof && proof.attachment) {
            this.proofRequired = false;
            this.attachment = proof.attachment;
        }
    },
    methods: {
        onSubmit() {
            this.$refs.nfrcForm
                .validate((valid) => {
                if (valid) {
                    this.$emit('on-submit', this.certificate);
                }
            });
        },
        updateProof(value) {
            if (!value) {
                return;
            }
            const form = this.$el;
            const proofField = form.querySelector('.upload-container');
            this.currentCertificate.attachment = value;
            this.proofRequired = false;
            setTimeout(() => {
                proofField.classList.add('is-required');
            }, 10);
        }
    },
});
